<template>
  <div id="blog-update" v-loading="loading">
    <div class="blog-update-warp">
      <h2 class="blog-update-title">{{ upadte.title }}</h2>
      <markdown-preview :contents="upadte.contents || ''" />
    </div>
  </div>
</template>

<script>
import MarkdownPreview from '@/components/MarkdownPreview'

export default {
  name: 'blog-update',
  components: {
    MarkdownPreview
  },
  data () {
    return {
      upadte: {
        title: '更新日志',
        contents: ''
      },
      loading: true
    }
  },
  methods: {
    getUpdate () {
      this.$http({
        url: this.$api.BLOG_GET_UPDATE_URL,
        method: 'GET'
      }).then(response => {
        if (response.status === 'success') {
          this.upadte.contents = response.data.contents
          this.loading = false
        } else {
          this.$message.error('服务器好像出错了...')
          this.loading = false
        }
      }).catch(() => {
        this.$message.error('哎呀...网络出错了，刷新一下吧')
        this.loading = false
      })
    }
  },
  created () {
    this.getUpdate()
  }
}
</script>

<style lang="stylus" scoped>
#blog-update
  position: relative
  padding: 0 10px
  width: 100%
  .blog-update-warp
    min-height: calc(100vh - 160px)
    position: relative
    padding: 30px
    width: 100%
    @media (max-width: 768px)
      padding: 30px 15px
    box-shadow: 0 0 5px 0 rgba(38, 42, 48, .1)
    .blog-update-title
      margin-bottom: 20px
</style>
